import React from "react"
import { Link } from "gatsby"

import SYheader from '../assets/SY-header.png';
import SYali from '../assets/SY-ali.jpg';
import SYinterviews from '../assets/SY-interviews.png';
import SYtesting from '../assets/SY-testing.png';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img src={SYheader} alt="Many wireframes from the Service Year project" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs">NCOC - Service Year</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>From a client provided list of about 50 users stores, I built the infrastructure and vision of what Service Year would look like as a product. I envisioned everything from:
                    <ul className="header-list">
                      <li className="list-item flex-grid-list-header">
                        <span className="">A method for young volunteers to find and apply to opportunities.</span>
                      </li>
                      <li className="list-item flex-grid-list-header">
                        <span className="">For those volunteers to track their experiences and receive recognition for completing their service year.</span>
                      </li>
                      <li className="list-item flex-grid-list-header">
                        <span className="">For non-profits to apply to become part of the Service Year system. And, once approved, be able to post volunteer opportunities, collect applicants, and accept and track their volunteers over time.</span>
                      </li>
                      <li className="list-item flex-grid-list-header">
                        <span className="">Finally, for the service year admins to be able to manage the site. Approving or declining requests to join, providing support for their users and being able to track how the platform performed over time.</span>
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">UX Director</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Stakeholder Interviews</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">User Interviews</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Site Map</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">User Flows</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Wireframes</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Prototypes</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">User Testing</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Direction and Coordination</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-sm">
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding flex flex-center col-100 row-padding-md mobile-margin-btm-xs">
              <h3 className="header--small">From the Beginning</h3>
              <p>Coming to ISL with only user stories, the NCOC team had an idea of what they wanted but didn’t fully understand what they were suggesting. To get the full grasp of the site the stories made up (and all the pages that would need to be around those pages) I made a giant sitemap as a vechile for discussion. From here we were able to discuss, prioritize, and get to work.</p>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="project">
                <img src={SYali} alt="Picture of Ali presenting a very large printed sitemap" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth process-bg">
        <div className="section-internal">
          <div className="align-center margin-btm-md">
            <h3 className="header--small">Process</h3>
          </div>
          <div className="flex-grid-5">
            <div>
              <h4 className="header--smallest">Kickoff</h4>
              <p>I talked through to the UX/UI side of the project, showing NCOC, through a sitemap, the extent of the project they were proposing and talking through a potential staring point.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Discovery</h4>
              <p>I conducted stakeholder and user interviews, discussed the result of these interviews with the client and decided on a path forward.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Wireframes</h4>
              <p>I iteratively worked with the NCOC to wireframe flows / pages and then build prototypes for testing.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Quantitative Testing</h4>
              <p>As flows were created, I tested them with potential users of the site. To work more efficiently, I also trained members of my internal team to conduct a user tests. As they conducted these tests, I oversaw the progress.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Coordination</h4>
              <p>I oversaw the design and development of this project - insuring that everything that was built to meet user expectations.</p>
              <span className="text-light">Myself</span>
            </div>
          </div>
        </div>
      </section>
      <section className="section-sm">
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-8 col-100">
              <div className="project project-neg-left row-padding-md row-padding-md-mob-none mobile-img-center">
                <img src={SYinterviews} alt="A few screens from a deck that outlined what we heard from stakeholder intereviews" />
              </div>
            </div>
            <div className="column column2 col-4 row-padding flex flex-center col-100">
              <h3 className="header--small">Stakeholder Interviews</h3>
              <p>I also conducted and shared out takeaways from Stakeholder Interviews. Talking to the team, many of them seemed to have differing opinions on aspects of the project. Talking to each of them individually and then communicating their views back to them during a meeting, helped everyone get on the same page.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-sm">
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-4 row-padding flex flex-center col-100 row-padding-md mobile-margin-btm-xs">
              <h3 className="header--small">Wireframes, User Testing & Iteration</h3>
              <p>Once we narrowed our focus and decided on priorities I worked iteratively, working towards the clients end goal. I did fuctionality analyses, made wireframes, tested, revised, discussed with the client, and worked with the designers and developers to ensure the implementation matched users expectations.</p>
            </div>
            <div className="column column2 col-8 col-100">
              <div className="project">
                <img src={SYtesting} alt="A few screens that outline how the user testing sessions went and some recommendations" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-sm">
        <div className="row">
          <div className="col-8 align-center img-center col-100">
            <h3 className="header--small">A Usable Product</h3>
            <p>After many many months, meetings, etc... I was proud of the relationships I had made with the client, the communication and coordination with our team and of course the production of a usable and understandable infrastructure that formed the base for design, development and most importantly a great product.</p>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
          <div className="footer-projects footer-adams">
            <div className="wrapper-project-footer flex-center column">
              <div className="section-internal flex flex-row space-between">
                <div>
                  <span className="next-previous">Next Project</span>
                  <Link className="project-link" to="/sam-adams/">
                    <h3 className="header--medium">Sam Adams - Market Research</h3>
                  </Link>
                  <span className="description">Market Research</span>
                </div>
                <Link className="project-link" to="/sam-adams/">
                  <img className="next-arrow" src={ArrowRight} alt="arrow" />
                </Link>
              </div>
            </div>
          </div>
      </section>
    </Layout>
  )
}
